import { MenuWrapper, SideNavWrapper, SignInWrapper, Wrapper } from 'components/Header/Menu/MenuStyles';
import MenuItem from 'components/Header/MenuItem';
import Button from 'components/Globals/Base/Button';
import Link from 'components/Globals/Base/Link';
import useBreakpoint from 'hooks/useBreakpoint';
import { MenuItemProps } from 'interfaces/navigation/Header';
import showLogin from 'services/Piano/showLogin';
import { useContext } from 'react';
import { UserContext } from 'providers/UserProvider';
import MyAccount from 'components/MyAccount';
import { showInstreamAd } from 'components/NavigationBar';
import { Microsites } from 'constants/constants';

const trackingData = {
  contentPlacementCD: 'header',
  eventAction: 'top header links',
  eventCategory: 'navigation',
};

interface Props {
  className?: string;
  currentUrl: string;
  leftMenu: MenuItemProps[];
  rightMenu: MenuItemProps[];
  subDomain?: string;
  onSearchClick: () => void;
  onKeyDown?: () => void;
}

const Menu = ({ className, currentUrl, leftMenu, rightMenu, subDomain, onSearchClick, onKeyDown }: Props) => {
  const isMobile = useBreakpoint('mdMin');
  const isTabletAndMobile = useBreakpoint('lgMax');
  const mobileMenu = [...leftMenu, ...rightMenu];
  const { userState: user } = useContext(UserContext);

  return (
    <SideNavWrapper className={className}>
      {(!isMobile || (isTabletAndMobile && Microsites.Crypto === subDomain)) &&
        !user.isLoggedIn &&
        Microsites.Well !== subDomain &&
        Microsites.Recommends !== subDomain &&
        Microsites.Education !== subDomain && (
        <SignInWrapper className={subDomain}>
          <span data-cy='brief'>Already have an account?</span>
          <Button
            className='sign-in-button'
            dataCy='signinBtnMobile'
            trackingData={{
              ...trackingData,
              eventLabel: 'sign in',
            }}
            onClick={showLogin}
            variant='tertiary'
            hasRightArrow={false}
          >
              SIGN IN
          </Button>
        </SignInWrapper>
      )}
      {!isMobile &&
        Microsites.Recommends !== subDomain &&
        Microsites.Education !== subDomain &&
        Microsites.Crypto !== subDomain && (
        <Wrapper>
          <Button
            type='button'
            onClick={() => {
              onSearchClick();
              showInstreamAd(subDomain);
            }}
            data-cy='searchButton'
            variant='tertiaryBlack'
            hasRightArrow={false}
          >
            {/* eslint-disable */}
              <label htmlFor='queryly_toggle'>SEARCH</label>
            </Button>
            {Microsites.Well !== subDomain &&
              (user.isLoggedIn ? (
                <MyAccount isCrypto={false} />
              ) : !user.hasAccess ? (
                <Link
                  href='/subscribe/'
                  trackingData={{
                    ...trackingData,
                    eventLabel: 'subscribe now',
                  }}
                  className='module impression-element'
                  data-content-placement='hamburger_menu_subscribe_cta'
                  dataCy='subscribeBtnMobile'
                  type='accentButton'
                  hasRightArrow={false}
                >
                  Subscribe Now
                </Link>
              ) : null)}
          </Wrapper>
        )}
      {isTabletAndMobile && Microsites.Crypto === subDomain && user.isLoggedIn && <MyAccount isCrypto={true} />}
      {!isMobile && Microsites.Crypto === subDomain && !user.isLoggedIn && !user.hasAccess && (
        <Wrapper>
          <Link
            href='/subscribe/'
            trackingData={{
              ...trackingData,
              eventLabel: 'subscribe now',
            }}
            dataCy='subscribeBtnMobile'
            type='accentButton'
            hasRightArrow={false}
          >
            Subscribe Now
          </Link>
        </Wrapper>
      )}
      {!isMobile && (
        <ul
          className='menu-list'
          data-cy='menuMobile'
        >
          {mobileMenu.map((item) => (
            <MenuItem
              key={item.id}
              {...item}
              currentUrl={currentUrl}
              isExpanded={item.label === 'Sections'}
            />
          ))}
        </ul>
      )}
      {isMobile && (
        <MenuWrapper>
          <ul
            className='menu-list'
            data-cy='menuLeft'
          >
            {leftMenu.map((item) => (
              <MenuItem
                key={item.id}
                {...item}
                currentUrl={currentUrl}
                isExpanded={item.label === 'Sections'}
              />
            ))}
          </ul>
          <ul
            className='menu-list'
            data-cy='menuRight'
          >
            {rightMenu.map((item, index) => (
              <MenuItem
                key={item.id}
                {...item}
                currentUrl={currentUrl}
                isExpanded={item.label === 'Sections'}
                onKeyDown={index === rightMenu.length - 1 && onKeyDown ? onKeyDown : undefined}
              />
            ))}
          </ul>
        </MenuWrapper>
      )}
    </SideNavWrapper>
  );
};

export default Menu;
