import { useContext, useState } from 'react';
import Link from 'components/Globals/Base/Link';
import useBreakpoint from 'hooks/useBreakpoint';
import { UserCircle } from 'icons/UserCircle';
import logOut from 'services/Piano/logOut';
import { UserContext } from 'providers/UserProvider';
import { useRouter } from 'next/router';
import * as S from './MyAccountStyles';

export interface MyAccountProps {
  className?: string;
  isCrypto?: boolean;
  isEurope?: boolean;
  dataCy?: string;
}

const MyAccount = ({ className, isCrypto, isEurope, dataCy }: MyAccountProps) => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const isMobile = useBreakpoint('mdMax');
  const isTabletAndMobile = useBreakpoint('lgMax');
  const { userState: user } = useContext(UserContext);
  const router = useRouter();
  const isSubscribePage = router.asPath === '/subscribe/';

  const setClassname = (europe: boolean | undefined, crypto: boolean | undefined) => {
    if (europe) {
      return 'europe';
    }
    if (crypto) {
      return 'crypto';
    }
    return '';
  };

  return isMobile || (isTabletAndMobile && isCrypto) ? (
    <S.MyAccountMobileWrapper
      className={`${isSubMenuOpen ? 'open' : ''} ${isCrypto ? 'crypto' : ''}`}
      data-cy={dataCy}
    >
      {user.hasAccess === false && !isSubscribePage && isMobile && (
        <Link
          className='subscribe-link module impression-element'
          href='/subscribe/'
          dataCy='subscribeBtnMobile'
          data-content-placement='hamburger_menu_subscribe_cta'
          type='accentButton'
          hasRightArrow={false}
        >
          Subscribe Now
        </Link>
      )}
      <S.UserButton
        type='button'
        onClick={() => setIsSubMenuOpen(!isSubMenuOpen)}
        data-cy='userIconBtn'
      >
        <UserCircle />
      </S.UserButton>

      <S.SubMenu className={`${setClassname(isCrypto, isEurope)} submenu`}>
        <S.SubMenuItem>
          <Link href='/my-account/'>MY ACCOUNT</Link>
        </S.SubMenuItem>
        <S.SubMenuItem>
          <button
            type='button'
            onClick={logOut}
            data-cy='logoutBtnMobile'
          >
            LOG OUT
          </button>
        </S.SubMenuItem>
      </S.SubMenu>
    </S.MyAccountMobileWrapper>
  ) : (
    <S.MyAccountWrapper
      className={className}
      data-cy={dataCy}
    >
      <S.AccordionWrapper className={isSubMenuOpen ? 'open' : ''}>
        <S.MyAccountLink
          href='/my-account/'
          className='my-account-link'
        >
          MY ACCOUNT
        </S.MyAccountLink>
        <S.Arrow className='arrow' />
      </S.AccordionWrapper>
      <S.SubMenu className='submenu'>
        <S.SubMenuItem>
          <button
            type='button'
            onClick={logOut}
            data-cy='logoutBtnDesktop'
          >
            LOG OUT
          </button>
        </S.SubMenuItem>
      </S.SubMenu>
    </S.MyAccountWrapper>
  );
};

export default MyAccount;
