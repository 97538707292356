/* eslint-disable camelcase */
import IterableTracker from 'services/Iterable';
import sha256 from 'crypto-js/sha256';
import geoIp from 'paymentApi/geoIp';
import { trackEvent } from 'services/Gtm/functions';
import CustomWindow from 'interfaces/Utils';
import { PianoHandlerEvent, Tinypass } from 'services/Piano/entities/Tinypass';
import { Subscriber } from 'services/Subscriber';
import CookieService from 'utils/miscUtils/CookieService';
import clearConsentCookies from 'utils/miscUtils/clearConsentCookies';
import {
  ConversionResponse,
  CheckoutCustomEvent,
  CustomEvent,
  ExperienceEvent,
  LoginDataProps,
  OfferTerms,
  ResponseVariables,
  Term,
  TermDetailsProps,
  OfferParams,
} from './entities/PianoCallbackParams';

declare let window: CustomWindow;

interface SessionData {
  checkoutStarted: boolean | undefined;
  currentTerm: Term | undefined;
  offerTermClicked: boolean;
  offerTerms: OfferTerms | undefined;
  termUnset: boolean;
}

const sessionData: SessionData = {
  checkoutStarted: undefined,
  currentTerm: undefined,
  offerTermClicked: false,
  offerTerms: undefined,
  termUnset: true,
};

const unsetTermProps = {
  currency: 'Currency unset',
  description: 'Description unset',
  name: 'Name unset',
  price: 'Price unset',
};

let reloadOnClose: boolean;

const pianoHandlers = (
  tp: Tinypass,
  setPaywall30: React.Dispatch<React.SetStateAction<string>>,
  sendEventOnConsent: ({ eventData, eventName, eventType }: PianoHandlerEvent) => void,
) => ({
  checkoutClose: (event: CheckoutCustomEvent) => {
    switch (event.state) {
      case 'close':
        if (reloadOnClose) {
          window.location.reload();
        } else {
          sendEventOnConsent({
            eventData: {
              form_name: 'Paywall',
              logged_in: !!tp.pianoId.getUser(),
            },
            eventName: 'form abandoned',
            eventType: 'track',
          });
        }
        break;
      case 'checkoutCompleted':
      case 'alreadyHasAccess':
        window.location.reload();
        break;
      default:
        break;
    }
  },
  checkoutComplete: async (conversion: ConversionResponse) => {
    const { chargeAmount, chargeCurrency, paymentId, termId } = conversion;
    try {
      const { name, description } = sessionData.currentTerm as Term;

      const orderCompletedPayload = {
        currency: chargeCurrency,
        logged_in: !!tp.pianoId.getUser(),
        products: sessionData.termUnset ?
          [
            {
              currency: chargeCurrency,
              description,
              id: termId,
              name,
              price: chargeAmount,
            },
          ] :
          [sessionData.currentTerm],
        timestamp: Date.now(),
        transaction_id: paymentId,
        value: chargeAmount,
      };
      sendEventOnConsent({
        eventData: orderCompletedPayload,
        eventName: 'payment info entered',
        eventType: 'track',
      });
      sendEventOnConsent({
        eventData: orderCompletedPayload,
        eventName: 'order completed',
        eventType: 'track',
      });
    } catch (error) {
      console.error(error);
    }

    if (tp.user.isUserValid()) {
      const subscriber = new Subscriber();
      await subscriber.init();
      subscriber.getIterableCampaign();
      const { email, iterableEmail, campaignId, templateId = null } = subscriber;

      if (iterableEmail && campaignId) {
        const iterableTracker = new IterableTracker(email);
        iterableTracker
          .trackCheckoutComplete(email, templateId, campaignId, conversion)
          .catch((error) => console.error(error));
      }
    }
  },
  checkoutCustomEvent: (event: CheckoutCustomEvent) => {
    switch (event.eventName) {
      case 'termSelected':
        {
          const { termId } = event.params;
          if (termId) {
            let selectedProduct;
            if (sessionData.offerTerms && sessionData.offerTerms[termId]) {
              selectedProduct = sessionData.offerTerms[termId];
              sessionData.termUnset = false;
            } else {
              sessionData.termUnset = true;
              selectedProduct = {
                ...unsetTermProps,
                id: termId,
              };
            }
            sessionData.offerTermClicked = true;
            sessionData.currentTerm = selectedProduct;
            const productAddedPayload = {
              currency: selectedProduct.currency,
              logged_in: !!tp.pianoId.getUser(),
              products: [selectedProduct],
              timestamp: Date.now(),
              value: selectedProduct.price,
            };

            sendEventOnConsent({
              eventData: productAddedPayload,
              eventName: 'product added',
              eventType: 'track',
            });
          }
        }
        break;
      case 'term-changed':
        {
          const { termId } = event.params;
          if (termId && termId !== 'no change') {
            let removedProduct;
            if (sessionData.offerTerms && sessionData.offerTerms[termId]) {
              removedProduct = sessionData.offerTerms[termId];
            } else {
              removedProduct = {
                ...unsetTermProps,
                id: termId,
              };
            }
            const productRemovedPayload = {
              currency: removedProduct.currency,
              logged_in: !!tp.pianoId.getUser(),
              products: [removedProduct],
              timestamp: Date.now(),
              value: removedProduct.price,
            };
            sendEventOnConsent({
              eventData: productRemovedPayload,
              eventName: 'product removed',
              eventType: 'track',
            });
          }
        }
        break;
      case 'payment-method-selected':
        if (sessionData.currentTerm) {
          const checkoutStartedPayload = {
            currency: sessionData.currentTerm.currency,
            logged_in: !!tp.pianoId.getUser(),
            products: [sessionData.currentTerm],
            timestamp: Date.now(),
            value: sessionData.currentTerm.price,
          };
          sendEventOnConsent({
            eventData: checkoutStartedPayload,
            eventName: 'checkout started',
            eventType: 'track',
          });
        }
        break;
      default:
        break;
    }
  },
  checkoutSelectTerm: (termDetails: TermDetailsProps) => {
    if (!sessionData.offerTermClicked) {
      const { termId } = termDetails;
      let selectedProduct;
      if (sessionData.offerTerms && sessionData.offerTerms[termId]) {
        selectedProduct = sessionData.offerTerms[termId];
        sessionData.termUnset = false;
      } else {
        sessionData.termUnset = true;
        selectedProduct = {
          ...unsetTermProps,
          id: termId,
        };
      }

      sessionData.currentTerm = selectedProduct;
      const productAddedPayload = {
        currency: selectedProduct.currency,
        logged_in: !!tp.pianoId.getUser(),
        products: [selectedProduct],
        timestamp: Date.now(),
        value: selectedProduct.price,
      };

      sendEventOnConsent({
        eventData: productAddedPayload,
        eventName: 'product added',
        eventType: 'track',
      });
    } else {
      sessionData.offerTermClicked = false;
    }
    if (tp.user.isUserValid()) {
      const { email } = tp.pianoId.getUser();

      const iterableTracker = new IterableTracker(email);

      iterableTracker
        .trackStartCheckout(window.location.href, termDetails.termName)
        .catch((error) => console.error(error));
    }
  },
  customEvent: (event: CustomEvent) => {
    switch (event.eventName) {
      case 'passwordlessClose':
        window.location.reload();
        break;
      default:
        break;
    }
  },
  experienceExecute: (event: ExperienceEvent) => {
    const { countryCode } = event.result.events[0].eventExecutionContext;
    tp.pianoId.init({ stage: `countryCode-${countryCode}` });
    tp.setCustomVariable('countryCode', countryCode);
  },
  loginSuccess: (loginData: LoginDataProps) => {
    const { uid: pianoUID, email } = tp.pianoId.getUser();
    const trackLogin = () => {
      try {
        sendEventOnConsent({
          eventData: {
            email,
            piano_user_id: pianoUID,
          },
          eventType: 'identify',
        });

        sendEventOnConsent({
          eventData: {
            login_type: loginData.source === 'OFFER' ? 'checkout' : 'standard',
            registration: loginData.registration,
            timestamp: Date.now(),
          },
          eventName: 'Signed In',
          eventType: 'track',
        });

        const trackingData = {
          eventAction: '',
          eventLabel: '',
        };

        if (loginData.registration) {
          geoIp(pianoUID);
          trackingData.eventAction = 'create account';
          trackingData.eventLabel = 'account creation successful';
        } else {
          trackingData.eventAction = 'sign in';
          trackingData.eventLabel = 'sign in successful';
        }

        trackEvent({
          ...trackingData,
          eventCategory: 'account',
          userIDCD: sha256(email).toString(),
        });
      } catch (error) {
        console.error(error);
      }
    };

    trackLogin();
    const domain = window.location.hostname;
    clearConsentCookies(domain);
    if (loginData.source !== 'OFFER') {
      window.location.reload();
    } else {
      reloadOnClose = true;
    }
  },
  logout: () => {
    const domain = window.location.hostname;
    CookieService.remove('uatr', {});
    CookieService.remove('uat', {});
    CookieService.remove('__utp', { domain });
    CookieService.remove('pianoUid', {});
    clearConsentCookies(domain);
    window.location.reload();
  },
  manualCreditRedeemed: () => {
    tp.template.close();
    window.location.reload();
  },
  setResponseVariable: ({ responseVariables: { paywall30 } }: ResponseVariables) => {
    setPaywall30(paywall30);
  },
  showOffer: async (offerParams: OfferParams) => {
    const { offerId, displayMode, experienceActionId, experienceId, templateId, templateVariantId } = offerParams;
    try {
      const response = await fetch(`/api/getPianoData/offerTerms/${offerId}/`);
      if (response.ok) {
        const terms = (await response.json()) as OfferTerms;
        sessionData.offerTerms = terms;
      }

      const productViewedPayload = {
        displayMode,
        experienceActionId,
        experienceId,
        logged_in: !!tp.pianoId.getUser(),
        products: [
          {
            offer_id: offerId,
          },
        ],
        templateId,
        templateVariantId,
        timestamp: Date.now(),
      };
      sendEventOnConsent({
        eventData: productViewedPayload,
        eventName: 'product viewed',
        eventType: 'track',
      });
    } catch (error) {
      console.error(error);
    }
    const iframes = document.querySelectorAll('iframe');
    iframes.forEach((i) => {
      if (i.name.includes('offer')) {
        i.setAttribute('aria-label', 'subscribe offer');
      }
    });
  },
});

export default pianoHandlers;
