import CustomLink from 'components/Globals/Base/Link';
import styled, { css } from 'styled-components';
import { breakpoint, font } from 'styles/globals';
import { getColor, Theme } from 'styles/theme';
import { arrowDown, arrowUp } from 'styles/icons';

export const MyAccountMobileWrapper = styled.div<{ theme: Theme }>`
  position: relative;
  width: fit-content;
  &.open {
    .submenu {
      display: block;
    }
  }
  .submenu {
    li {
      padding-bottom: 16px;
    }
    width: 180px;
    li:first-child {
      border-bottom: none;
      padding-bottom: 16px;
    }
    li:last-child {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .subscribe-link {
    position: relative;
    right: 16px;
    top: -7px;
  }

  &.crypto {
    display: flex;
    justify-content: flex-end;
    width: inherit;
    gap: 16px;

    .subscribe-link {
      right: 0;
      top: 0;
    }

    .submenu {
      top: 40px;
    }
  }
`;

const arrow = css<{ theme: Theme }>`
  padding: 5px;
  border-width: 0 2px 2px 0;
`;

export const Arrow = styled.span<{ theme: Theme }>`
  ${arrowDown}
  ${arrow}
  margin-bottom: 4.5px;
`;

export const UserButton = styled.button<{ theme: Theme }>`
  svg {
    width: 28px;
    height: 28px;
  }
  &:hover {
    .arrow {
      border-color: ${getColor('iconAccentPrimary')};
    }
  }

  &:focus {
    .arrow {
      border-color: ${getColor('iconSecondary')};
    }
  }
`;

export const MyAccountWrapper = styled.div<{ theme: Theme }>`
  position: relative;
  width: fit-content;
  @media (${breakpoint.mdMin}) {
    &:hover {
      .submenu {
        display: block;
      }
    }
  }

  @media (${breakpoint.mdMin}) {
    &:hover {
      .my-account-link {
        color: ${getColor('textLink')};
      }
      .arrow {
        ${arrowUp}
        ${arrow}
        border-color: ${getColor('textLink')};
      }
    }
  }
`;

export const AccordionWrapper = styled.div<{ theme: Theme }>`
  &.open {
    .arrow {
      transform: rotate(-90deg) translateX(-2px);
      border-color: ${getColor('iconAccentPrimary')};
    }
    .my-account-link {
      color: ${getColor('textLink')};
    }
    @media (${breakpoint.lgMax}) {
      +ul {
        display: block;
      }
    }
  }
`;

export const MyAccountLink = styled(CustomLink)<{ theme: Theme }>`
  margin-right: 8px;
  color: ${getColor('textPrimary')};
  font-family: ${font.graphikCond};
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;

  @media (${breakpoint.lgMin}) {
    font-size: 20px;
    line-height: 24px;
    margin-right: 24px;
  }
`;

export const SubMenu = styled.ul<{ theme: Theme }>`
  display: none;
  position: absolute;
  z-index: 1;
  right: 0;
  background-color: ${getColor('surfaceWhite')};
  box-shadow: 0 2px 4px 2px ${getColor('surfaceBlack')}19;
  width: 140px;
  padding: 16px;

  @media (${breakpoint.lgMin}) {
    width: 180px;
  }

  li:first-child {
    padding-bottom: 8px;
  }

  li:last-child {
    button {
      color: ${getColor('textAccentSecondary')};
    }
    padding-top: 8px;
  }

  &.crypto {
    left: 0;
  }
`;

export const SubMenuItem = styled.li`
  button, a {
    font-family: ${font.graphikCond};
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;

    @media (${breakpoint.lgMin}) {
      font-size: 20px;
      line-height: 24px;
    }
  }
`;
