import styled, { css } from 'styled-components';
import { breakpoint } from 'styles/globals';
import { Theme, getColor } from 'styles/theme';

const linkStatesStyles = css<{ theme: Theme }>`
  // Need to overwrite inline styles coming from binance-widget
  color: ${getColor('textPrimary')} !important;
  &:hover {
    // Need to overwrite inline styles coming from binance-widget
    color: ${getColor('textLink')} !important;
  }
  &:active,
  &:focus {
    // Need to overwrite inline styles coming from binance-widget
    color: ${getColor('textSecondary')} !important;
  }
`;

const BinanceTickerWrapper = styled.div<{ theme: Theme }>`
    border-top: 1px solid ${getColor('borderSecondary')};
    padding-top: 8px;
    min-height: 78px;
    // Needed because of reset css.
    img {
      max-width: 80px;
    }

    a {
      ${linkStatesStyles}

      p {
        ${linkStatesStyles}
      }
    }

    .binance-widget-container {
      row-gap: 8px;
    }

    .binance-widget-ticker-list {
      border-bottom: 1px solid ${getColor('borderSecondary')};
      padding-bottom: 8px;
    }
    
    .binance-widget__footer {
      width: 100%;
      max-width: 1440px;
      margin: auto;
      padding: 8px 0;

      a {
        padding: 0 8px;
        
        @media (${breakpoint.mdMin}) {
          padding: 0 24px;
        }
        
        @media (${breakpoint.lgMin}) {
          padding: 0 48px;
        }
      }
    }
    
    // Kept for styling that will be required in future tasks.
    /*
    .binance-widget-ticker {
      animation-duration: 100s;
    }
    .binance-widget-coins > a > div > img:last-child {
      //!important required to overwrite the hue-rotate that comes from the server, in order to modify the graph color
      filter: hue-rotate(350deg) !important;
    }
  */
`;

export default BinanceTickerWrapper;
