import { EventData } from 'services/Gtm/entities';
import Link from 'components/Globals/Base/Link';
import getArticlePageTestId from 'constants/testsIds/articlePage';
import { SharedVariablesForTagAndSection } from 'interfaces/components/SharedVariablesForTagAndSection';
import { withErrorBoundary } from 'components/ErrorBoundary';
import { UIFragments } from 'utils/log/constants/uiFragments';
import Wrapper from './ArticleHeroEyebrowStyles';

export interface ArticleHeroEyebrowProps {
  primarySection: SharedVariablesForTagAndSection;
  primaryPostTag: SharedVariablesForTagAndSection;
  className?: string;
  trackingData?: EventData;
  eyebrowDataCy?: string;
  eyebrowLinkDataCy?: string;
}

const ArticleHeroEyebrow = ({
  className,
  primarySection,
  primaryPostTag,
  trackingData,
  eyebrowDataCy = getArticlePageTestId('EYEBROW'),
  eyebrowLinkDataCy = getArticlePageTestId('SECTION_EYEBROW'),
}: ArticleHeroEyebrowProps) => {
  const eyebrowTagLabel = primaryPostTag.name;
  const eyebrowSectionLabel = primarySection.name;

  const trackingDataEyebrow = {
    contentPlacementCD: 'article hero',
    eventAction: `${eyebrowSectionLabel} : ${eyebrowTagLabel}`,
    eventCategory: 'article',
    nonInteraction: 0,
  };

  return (
    <Wrapper
      className={`${className} hero-eyebrow module impression-element`}
      data-cy={eyebrowDataCy}
      data-content-placement='eyebrow'
    >
      {eyebrowSectionLabel && (
        <Link
          href={`${primarySection.link}`}
          type='red'
          className='link'
          trackingData={
            trackingData ?
              { ...trackingData, eventLabel: `Section click: ${eyebrowSectionLabel}` } :
              { ...trackingDataEyebrow, eventLabel: `${eyebrowSectionLabel}` }
          }
          ariaLabel={`Go to ${eyebrowSectionLabel}`}
          dataCy={eyebrowLinkDataCy}
        >
          {eyebrowSectionLabel}
        </Link>
      )}
      {eyebrowSectionLabel && eyebrowTagLabel && <span aria-hidden='true'>·</span>}
      {eyebrowTagLabel && (
        <Link
          href={`${primaryPostTag.link}`}
          type='red'
          className='link'
          trackingData={
            trackingData ?
              { ...trackingData, eventLabel: `Tag click: ${eyebrowTagLabel}` } :
              { ...trackingDataEyebrow, eventLabel: `${eyebrowTagLabel}` }
          }
          ariaLabel={`Go to ${eyebrowTagLabel}`}
          dataCy={getArticlePageTestId('TAG_EYEBROW')}
        >
          {eyebrowTagLabel}
        </Link>
      )}
    </Wrapper>
  );
};

export default withErrorBoundary(ArticleHeroEyebrow, { fragment: UIFragments.NAVIGATION });
