import React from 'react';
import { BaseAdConfig } from 'interfaces/ads/Ad';
import { RightRailContainer, RightRailAd, RightRailStickyContainer, RightRailStickyAd } from './Styles';
import AdSlotToggle from './AdSlotToggle';

const RightRailFlex: React.FC<BaseAdConfig> = ({ id, sticky, dataCy }: BaseAdConfig) =>
  (sticky ? (
    <RightRailStickyContainer data-cy={dataCy}>
      <RightRailStickyAd data-cy={`${dataCy}-child`}>
        <AdSlotToggle
          dataCy={`RightRailFlex${id}`}
          placementName='RightRailFlex'
          index={id + 1}
          slotId={`RightRailFlex${id}`}
        />
      </RightRailStickyAd>
    </RightRailStickyContainer>
  ) : (
    <RightRailContainer data-cy={dataCy}>
      <RightRailAd data-cy={`${dataCy}-child`}>
        <AdSlotToggle
          dataCy={`RightRailFlex${id}`}
          placementName='RightRailFlex'
          index={id + 1}
          delay={1}
          slotId={`RightRailFlex${id}`}
        />
      </RightRailAd>
    </RightRailContainer>
  ));

export default RightRailFlex;
