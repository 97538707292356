import Error500Standalone from 'components/Error500Redesign/Error500Standalone';
import { HeaderNav } from 'interfaces/navigation/Header';
import mockPageData from 'lib/mockData/page-data';
import { PageViewDataLayerObject } from 'services/Gtm/entities';
import setPageGtmConfig from 'services/Gtm/setPageGtmConfig';
import { IterablePageData } from 'services/Iterable/entities';
import setIterablePageData from 'services/Iterable/setIterablePageData';
import { setPermutivePageConfig } from 'services/Permutive';
import { PermutivePageConfig } from 'services/Permutive/entities';
import { setMockPianoConfig } from 'services/Piano';
import { PianoPageConfig } from 'services/Piano/entities/PianoPageConfig';
import handleStaticPropsError from 'utils/handleStaticPropsError';
import { mockHeadData } from 'utils/metaDataUtils/headData';

export function getStaticProps() {
  try {
    const menu: HeaderNav = {
      domainLogo: null,
      leftMenu: [],
      rightMenu: [],
      subDomain: 'own',
      topicNavigation: [],
    };

    const footer = {
      footerDisclaimer: '',
      footerNav: [],
      socialList: [],
    };

    const dataLayer: PageViewDataLayerObject = setPageGtmConfig(mockPageData);
    const iterablePageViewData: IterablePageData = setIterablePageData(mockPageData);
    const permutivePageConfig: PermutivePageConfig = setPermutivePageConfig(mockPageData);
    const pianoPageConfig: PianoPageConfig = setMockPianoConfig();

    return {
      props: {
        dataLayer,
        footer,
        headData: mockHeadData,
        headerNav: menu,
        iterablePageViewData,
        permutivePageConfig,
        pianoPageConfig,
      },
    };
  } catch (e) {
    return handleStaticPropsError(e as Error);
  }
}

export default Error500Standalone;
