import styled from 'styled-components';
import { breakpoint } from 'styles/globals';
import { imageAndTitleLinkHover } from 'styles/imageAndTitleLinkHover';
import { Theme, getColor, getBodyStyle } from 'styles/theme';
import Heading from 'styles/typography/Heading';

export const Wrapper = styled.div<{ theme: Theme }>`
  ${imageAndTitleLinkHover}
  ${getBodyStyle('graphikCond', { default: 'xlarge' }, 'light')}
  padding: 24px 0;
`;

export const ImageWrapper = styled.div`
  width: 100%;

  @media (${breakpoint.lgMin}) {
    padding-top: 48px;
    min-width: 448px;
  }

  @media (${breakpoint.xlMin}) {
    min-width: 640px;
  }
`;

export const TextWrapper = styled.div<{ theme: Theme }>`
  padding-top: 24px;
  width: 100%;

  @media (${breakpoint.lgMin}) {
    padding-top: 48px;
  }
`;

export const Section = styled.span<{ theme: Theme }>`
    display: block;
    ${getBodyStyle('graphikCond', { default: 'small', mdMin: 'default' }, 'semiBold')}
    text-transform: uppercase;
    margin-bottom: 10px;
`;

export const Title = styled(Heading)`
  margin-bottom: 10px;
  overflow: hidden;
  color: inherit;
`;

export const Excerpt = styled.p<{ theme: Theme }>`
  margin: 16px 0;
`;

export const Publishdate = styled.time<{ theme: Theme }>`
  display: block;
  color: ${getColor('textSecondary')};
  font-size: 14px;
  line-height: 100%;
`;

export const ContentWrapper = styled.div<{ theme: Theme }>`
  background: linear-gradient(
        to bottom, 
        rgba(0, 0, 0, 0) 0px, 
        rgba(0, 0, 0, 0) 24px, 
        ${getColor('surfaceBrand')} 24px
    );
  padding: 0 16px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (${breakpoint.mdMin}) {
    padding: 0 24px 24px;
  }

  @media (${breakpoint.lgMin}) {
    padding: 0 48px 24px; 
    flex-direction: row;
    gap: 24px;
  }
`;
