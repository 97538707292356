import { ContentTitle } from 'components/ContentTitle';
import { SectionArticles } from 'interfaces/Section';
import CustomLink from 'components/Globals/Base/Link';
import { NextCustomImage } from 'components/Image';
import { Sections, Section, Wrapper, Title } from 'components/Home/SectionsBlock/SectionsBlockStyles';
import useBreakpoint from 'hooks/useBreakpoint';
import { ellipsis } from 'utils/stringUtils';
import RightRailFlex from 'components/Ad/AdSlot/RightRailFlex';
import { setEventAction } from 'utils/trackDataUtils';
import parseCaptionWhenAltTextEmpty from 'utils/parsingUtils/clientSideParsing';
import ADS_TEST_IDS from 'constants/testsIds/ads';
import { SECTIONS_BLOCK_TEST_IDS } from 'constants/testsIds/homepage';
import { ARTICLE_CARD_TEST_IDS } from 'constants/testsIds/articleComponents';

export interface SectionsBlockProps {
  heading: string;
  items: SectionArticles[];
  adIndex?: number;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SectionsBlock = ({ heading, items, adIndex }: SectionsBlockProps) => {
  const isMdMin = useBreakpoint('mdMin');
  const isLgMin = useBreakpoint('lgMin');
  const isXlMin = useBreakpoint('xlMin');
  const truncTitle = (title: string) => {
    const mdTruncTitle = ellipsis(title, 84);
    const lgTruncTitle = ellipsis(title, 70);
    const xlTruncTitle = ellipsis(title, 66);

    if (!isMdMin) return title;
    if (isMdMin && !isLgMin) return mdTruncTitle;
    if (isLgMin && !isXlMin) return lgTruncTitle;
    if (isXlMin) return xlTruncTitle;

    return title;
  };

  return (
    <Wrapper data-cy={SECTIONS_BLOCK_TEST_IDS.SECTION_WRAPPER}>
      {heading && (
        <ContentTitle
          className='heading'
          font='saolText'
          tag='h2'
          dataCy={SECTIONS_BLOCK_TEST_IDS.SECTION_BLOCK_TITLE}
        >
          {heading}
        </ContentTitle>
      )}
      <Sections>
        {items.map((item) => {
          const sectionTrackingData = {
            contentPlacementCD: heading,
            eventAction: `${item.name} : ${heading}`,
            eventCategory: 'article',
            eventLabel: 'category click',
          };

          const featuredTrackingData = {
            contentPlacementCD: heading,
            eventAction: setEventAction(item.featuredArticle.eyebrowSectionLabel, item.featuredArticle.eyebrowTagLabel),
            eventCategory: 'article',
            eventLabel: `title click : ${item.featuredArticle.title}`,
          };

          const featuredBannerTrackingData = {
            ...featuredTrackingData,
            eventLabel: `banner click : ${item.featuredArticle.title}`,
          };

          return (
            <Section
              key={item.slug}
              data-cy={ARTICLE_CARD_TEST_IDS.CARD_WRAPPER}
            >
              <CustomLink
                dataCy={ARTICLE_CARD_TEST_IDS.EYEBROW}
                ariaLabel={`Go to section ${item.name}`}
                href={item.slug}
                className='label'
                trackingData={sectionTrackingData}
              >
                {item.name}
              </CustomLink>
              <CustomLink
                ariaLabel={`Go to featured article of the section ${item.featuredArticle.title}`}
                href={item.featuredArticle.slug}
                trackingData={featuredBannerTrackingData}
                className='image'
                dataCy={ARTICLE_CARD_TEST_IDS.IMAGE_LINK}
              >
                <NextCustomImage
                  src={item.featuredArticle.mediaItem.mediaItemUrl}
                  data-cy={ARTICLE_CARD_TEST_IDS.IMAGE}
                  alt={parseCaptionWhenAltTextEmpty(item.featuredArticle.mediaItem)}
                  width='246'
                  height='164'
                  sizes='(max-width: 768px) 100vw, 450px'
                />
              </CustomLink>
              <CustomLink
                dataCy={ARTICLE_CARD_TEST_IDS.TITLE}
                href={item.featuredArticle.slug}
                trackingData={featuredTrackingData}
                ariaLabel={`Go to featured article of the section ${item.featuredArticle.title}`}
                className='title'
              >
                <Title className='featured-title'>{truncTitle(item.featuredArticle.title)}</Title>
              </CustomLink>
              {item.articles.map((article) => {
                const articleTrackingData = {
                  contentPlacementCD: heading,
                  eventAction: `${article.eyebrowSectionLabel} : ${article.eyebrowTagLabel}`,
                  eventCategory: 'article',
                  eventLabel: `title click : ${article.title}`,
                };
                return (
                  <CustomLink
                    dataCy={ARTICLE_CARD_TEST_IDS.TITLE}
                    key={article.slug}
                    href={article.slug}
                    className='article'
                    trackingData={articleTrackingData}
                    ariaLabel={`Go to section article ${article.title}`}
                  >
                    <Title>{truncTitle(article.title)}</Title>
                  </CustomLink>
                );
              })}
            </Section>
          );
        })}
      </Sections>
      <div>
        <RightRailFlex
          dataCy={`${ADS_TEST_IDS.RIGHT_RAIL_FLEX_STICKY}`}
          id={3}
          sticky
        />
      </div>
    </Wrapper>
  );
};

export default SectionsBlock;
