import { css, RuleSet } from 'styled-components';
import { getColor, Theme } from 'styles/theme';

export type IconName =
  | 'arrow-button-slick'
  | 'bullet'
  | 'arrow-states'
  | 'svg-states'
  | 'next-arrow'
  | 'prev-arrow'
  | 'up-arrow'
  | 'email'
  | 'facebook-square'
  | 'twitter'
  | 'linkedin'
  | 'share-icon'
  | 'down-arrow'
  | 'close-icon'
  | 'close-icon-bold';

export type IconSize = 'xsmall' | 'small' | 'default' | 'large';

const arrow = css`
  border-style: solid;
  border-color: inherit;
  border-width: 0 1.5px 1.5px 0;
  border-radius: 10%;
  display: inline-block;
  padding: 3px;
  width: 9px;
  height: 9px;
  content: '';
`;

export const arrowRight = css`
  ${arrow}
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  margin-bottom: 1px;
  margin-right: 2px;
  margin-top: 3px;
`;

export const arrowUp = css`
  ${arrow}
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  margin-bottom: -2px;
`;

export const arrowDown = css`
  ${arrow}
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-bottom: 2.5px;
`;

export const arrowLeft = css`
  ${arrow}
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  margin-bottom: 2px;
  margin-top: 3px;
`;

export const checkmark = css<{ theme: Theme }>`
  --width: 5.5px;
  --height: 11px;
  --border-width: 2.5px;
  --color: ${getColor('iconWhite')};
  background-color: transparent;
  width: var(--width);
  height: var(--height);
  border: solid var(--color);
  border-width: 0 var(--border-width) var(--border-width) 0;
  transform: rotate(45deg);
  border-radius: 1px;
`;

export const yesNoIcons = css`
  .has-vivid-cyan-blue-color {
      content: url('/icons/ProIcon.svg');
      padding-left: 24px;
    }

    .has-vivid-purple-color {
      content: url('/icons/ConIcon.svg');
      padding-left: 24px;
    }
`;

export const arrowButtonSlick = css<{ theme: Theme }>`
  width: 24px;
  height: 40px;
  background-color: ${getColor('surfaceWhite')}DD;
  display: flex !important; // Overrides inline style for the Slick carousel
  align-items: center;
  justify-content: center;
  z-index: 1;

  &:hover {
    background-color: ${getColor('surfaceWhite')};
  }

  & > div {
    width: auto;
    height: auto;
  }

  span {
    border-color: ${getColor('borderTertiary')};
  }
`;

export const arrowStates = css<{ theme: Theme }>`
  border-color: ${getColor('iconPrimary')};
  &:hover {
    border-color: ${getColor('iconAccentPrimary')};
  }
    
  &:focus, &:active {
    border-color:${getColor('iconSecondary')};
  }
`;

export const svgStates = css<{ theme: Theme }>`
  &:active,  &:focus, &:focus-within {
    &:before, &:after {
      background-color: ${getColor('iconTertiary')};
    }
  }
  &:hover {
    &:before, &:after {
      background-color: ${getColor('iconAccentPrimary')};
    }
  }
`;

export const bullet = css<{ theme: Theme }>`
  font-size: 0;
  text-align: center;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${getColor('iconTertiary')};
  }

  &:hover {
    &::before {
      background: ${getColor('shadeBrandPrimary200')};
    }
  }

  &:focus {
    &::before {
      background: ${getColor('iconSecondary')};
    }
  }
`;

export interface IconSizes {
  [key: string]: RuleSet<object> | null;
}

export interface Size {
  width: number;
  height: number;
}

export const arrowSizes: IconSizes = {
  default: css`
    width: 11.5px;
    height: 11.5px;
    border-width: 0 2px 2px 0;
  `,
  large: css`
    width: 14.5px;
    height: 14.5px;
    border-width: 0 2.5px 2.5px 0;
  `,
  small: css`
    width: 9px;
    height: 9px;
  `,
  xsmall: css`
    width: 5px;
    height: 5px;
    border-width: 0 1.3px 1.3px 0;
  `,
};

export const svgSizes: IconSizes = {
  default: css`
    width: 24px;
    height: 24px;
    mask-size: 24px 24px;
  `,
  large: css`
    width: 32px;
    height: 32px;
    mask-size: 32px 32px;
  `,
  small: css`
    width: 20px;
    height: 20px;
    mask-size: 20px 20px;
  `,
};

const getSize = (size: Size) => css`
  width: ${size.width}px;
  height: ${size.height}px;
  mask-size: ${size.width}px ${size.height}px;
`;

/**
 *  getIcon(iconName) - css/svg icon
 *  getIcon(iconName, 'small' | 'large' ) - using predefined default sizes
 *  getIcon(iconName, {width: 30, height: 20}) - using custom sizes
 *
 * @param iconName - the iconName (Svg or css)
 * @param size - can be predefined ( small, large ) or custom {height: 32, width: 34}
 * @returns The css/svg icon with iconName
 *
 * Can be used like getIcon(iconName)
 */

export const getIcon = (iconName: IconName | (string & {}), size?: IconSize | Size) => {
  let icon;
  let iconSize;
  const arrowSize = size ? arrowSizes[size as keyof typeof arrowSizes] : null;
  const defaultSize = size ? svgSizes[size as keyof typeof svgSizes] : svgSizes.default;
  const svgSize = typeof size === 'object' ? getSize(size) : defaultSize;

  switch (iconName) {
    case 'arrow-button-slick':
      icon = arrowButtonSlick;
      break;
    case 'bullet':
      icon = bullet;
      break;
    case 'arrow-states':
      icon = arrowStates;
      break;
    case 'next-arrow':
      icon = arrowRight;
      iconSize = arrowSize;
      break;
    case 'prev-arrow':
      icon = arrowLeft;
      iconSize = arrowSize;
      break;
    case 'up-arrow':
      icon = arrowUp;
      iconSize = arrowSize;
      break;
    case 'down-arrow':
      icon = arrowDown;
      iconSize = arrowSize;
      break;
    case 'svg-states':
      icon = svgStates;
      break;
    default:
      iconSize = svgSize;
      icon = css<{ theme: Theme }>`
        content: '';
        mask: url("/icons/svg/${iconName}.svg") no-repeat center center;
        display: block;
        background-color: ${getColor('iconSecondary')};
      `;
  }

  return css<{ theme: Theme }>`
    ${icon}
    ${iconSize}
  `;
};
