/* eslint-disable max-len */
import { WebTarget } from 'styled-components';
import RawHtml from 'utils/miscUtils/rawHtml';
import BylineList from 'components/Byline';
import Link from 'components/Globals/Base/Link';
import useDateTime from 'hooks/useDateTime';
import { MediaItem } from 'interfaces/content/Media';
import { Author } from 'interfaces/content/Authors';
import { EventData } from 'services/Gtm/entities';
import { GridAuthorWithUri } from 'interfaces/content/Ranking';
import React, { ForwardedRef } from 'react';
import parseCaptionWhenAltTextEmpty from 'utils/parsingUtils/clientSideParsing';
import { ARTICLE_PAGE_TEST_IDS } from 'constants/testsIds/articlePage';
import dynamic from 'next/dynamic';
import * as S from './PostStyles';
import * as CS from './CommonStyle';

export interface PostProps {
  as?: WebTarget;
  image: MediaItem | string | null;
  eyebrowSectionLabel: string;
  eyebrowSectionLink: string;
  eyebrowTopicLabel?: string;
  eyebrowTopicLink?: string;
  title: string;
  titleLink: string;
  type?: string;
  authors: Author[] | GridAuthorWithUri[];
  date: string;
  trackingData?: EventData;
  priority?: boolean;
  className?: string;
}

const NextCustomImage = dynamic(() => import('components/Image'));

// eslint-disable-next-line react/display-name
const Post = React.forwardRef((props: PostProps, ref: ForwardedRef<HTMLLIElement> | null) => {
  const {
    image,
    title,
    titleLink,
    type,
    eyebrowSectionLabel,
    eyebrowSectionLink,
    eyebrowTopicLabel,
    eyebrowTopicLink,
    authors,
    date,
    trackingData,
    priority = false,
    className,
    as = 'li',
  } = props;
  const localDate = useDateTime(date, 'date');
  const recommendsArticle = titleLink.match(/recommends/g);

  // this comes from the article page for latest component that renders both the primary section and primary topic
  const showEyebrowTopic =
    eyebrowTopicLabel && eyebrowTopicLink ? (
      <>
        <CS.Hyphen
          className='hyphen'
          aria-hidden='true'
        >
          {' '}
          -{' '}
        </CS.Hyphen>
        <CS.SectionLink
          $type={type}
          className='link-wrapper'
        >
          <Link
            className='topic-link'
            dataCy='postEyebrow'
            href={eyebrowTopicLink}
            trackingData={
              trackingData && {
                ...trackingData,
                eventLabel: `category click : ${trackingData.eventLabel || eyebrowTopicLabel}`,
              }
            }
            ariaLabel={`Go to article's topic ${eyebrowTopicLabel}`}
            type='red'
          >
            {eyebrowTopicLabel}
          </Link>
        </CS.SectionLink>
      </>
    ) : null;

  const renderEyebrowSectionLink = () => (
    <CS.SectionLink
      $type={type}
      className='link-wrapper'
    >
      <Link
        className='section-link'
        dataCy='postEyebrow'
        href={eyebrowSectionLink}
        type='red'
        trackingData={
          trackingData && {
            ...trackingData,
            eventLabel: `category click : ${trackingData.eventLabel || eyebrowSectionLabel}`,
          }
        }
        ariaLabel={`Go to article's section ${eyebrowSectionLabel}`}
      >
        {eyebrowSectionLabel}
      </Link>
    </CS.SectionLink>
  );

  return (
    <S.Wrapper
      ref={ref}
      className={className}
      as={as}
    >
      <S.Card
        className='card'
        data-cy={ARTICLE_PAGE_TEST_IDS.WRAPPER}
      >
        {image && (
          <NextCustomImage
            className='card-image'
            src={typeof image === 'string' ? image : image?.mediaItemUrl}
            alt={parseCaptionWhenAltTextEmpty(image)}
            width='735'
            height='490'
            sizes='(max-width: 768px) 100vw, 450px'
            priority={priority}
            data-cy={ARTICLE_PAGE_TEST_IDS.IMAGE}
          />
        )}
        {eyebrowSectionLabel !== 'No Primary Section Found' &&
          eyebrowSectionLabel &&
          (type !== 'article-redesign-latest' ? (
            renderEyebrowSectionLink()
          ) : (
            <div className='eyebrow'>
              {renderEyebrowSectionLink()}
              {showEyebrowTopic}
            </div>
          ))}
        <S.TitleLink
          className='title-link-wrapper'
          $hasEyebrow={eyebrowSectionLabel !== undefined && eyebrowSectionLabel !== 'No Primary Section Found'}
        >
          <Link
            dataCy='postTitle'
            href={titleLink}
            trackingData={
              trackingData && { ...trackingData, eventLabel: `title click : ${trackingData.eventLabel || title}` }
            }
            ariaLabel={`Go to full article ${title}`}
            className='title-link'
            type='primary'
          >
            {RawHtml({ html: title })}
          </Link>
        </S.TitleLink>
        <CS.CardInfoWrapper
          data-cy='postAuthors'
          className='card-info-wrapper'
        >
          <BylineList
            className='author-list'
            bylineList={authors}
            trackingData={trackingData}
          />
          {!recommendsArticle && (
            <S.PubDate
              data-cy='postDate'
              className='date'
            >
              {localDate}
            </S.PubDate>
          )}
        </CS.CardInfoWrapper>
      </S.Card>
    </S.Wrapper>
  );
});

export default Post;
