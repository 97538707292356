import styled from 'styled-components';
import { breakpoint } from 'styles/globals';
import { getIcon } from 'styles/icons';
import { Theme, getColor } from 'styles/theme';

export const DrawerToggle = styled.input`
  &.drawer-toggle {
    display: none;

  &:checked {
    & ~ .drawer-menu {
      visibility: visible;
      left: 0;
    }

    & ~ .overlay {
      visibility: visible;
      opacity: 0.5;
    }
  }
}
`;

export const DrawerToggleButton = styled.label<{ theme: Theme }>`
  &.hamburger-toggle {
    display: block;
    width: 30px;
    height: 23px;
    cursor: pointer;
    margin-right: 16px;
    position: relative;
    margin-bottom: 10px;

    @media (${breakpoint.lgMin}) {
		  margin-right: 48px;	
	  } 
    
    svg {
      transition: all .2s ease-in-out;
      width: 30px;
      height: 30px;
    }

    &:hover {
      svg{
        fill: ${getColor('accentPrimary')};
        transition: all .2s ease-in-out;
      }
    }

    &:focus, &:active {
      svg {
        fill: ${getColor('iconSecondary')};
      }
    }
  }

  &.close-menu {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    cursor: pointer;
    position: relative;
    width: 27px;
    height: 23px;

    @media (${breakpoint.mdMin}) {
      height: 40px;
      width: 33px;
    }
  
    ${getIcon('svg-states')};

    &::before {
      margin: auto;
      ${getIcon('close-icon', { height: 19, width: 18 })};
      background-color: ${getColor('neutralBlack')};
      
      @media (${breakpoint.mdMin}) {
        ${getIcon('close-icon', { height: 22, width: 23 })};
        background-color: ${getColor('neutralBlack')};
      }
    }
      
  }
`;
